import React, { useState } from 'react';
import { useChatContext } from 'stream-chat-react';

import { UserList } from './'; // Assuming there are other components in the same directory
import { CloseCreateChannel } from '../assets';

const ChannelNameInput = ({ channelName = '', setChannelName }) => {
    const handleChange = (event) => {
        event.preventDefault();
        setChannelName(event.target.value);
    };

    return (
        <div className="channel-name-input__wrapper">
            <p>Name</p>
            <input value={channelName} onChange={handleChange} placeholder="channel-name" />
            <p>Add Members</p>
        </div>
    );
};

const ConfirmDeleteButton = ({ onClick }) => {
    return (
        <button className="confirm-delete-button" onClick={onClick}>
            Confirm Delete
        </button>
    );
};

const EditChannel = ({ closeEditMenu }) => { // Receive closeEditMenu as a prop
    const { channel } = useChatContext();
    const [channelName, setChannelName] = useState(channel?.data?.name);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [isDeleteConfirmed, setIsDeleteConfirmed] = useState(false);

    const updateChannel = async (event) => {
        event.preventDefault();

        const nameChanged = channelName !== (channel.data.name || channel.data.id);

        if (nameChanged) {
            await channel.update({ name: channelName }, { text: `Channel name changed to ${channelName}` });
        }

        if (selectedUsers.length) {
            await channel.addMembers(selectedUsers);
        }

        setChannelName('');
        setSelectedUsers([]);
    };

    const handleDeleteConfirmation = async () => {
        if (isDeleteConfirmed) {
            try {
                // Delete the channel
                await channel.delete();
                console.log('Channel deleted');
                closeEditMenu(); // Close edit menu after deletion
            } catch (error) {
                console.error('Error deleting channel:', error);
            }
        } else {
            setIsDeleteConfirmed(true); // Confirm deletion
        }
    };

    return (
        <div className="edit-channel__container">
            {!isDeleteConfirmed && (
                <button className="delete-channel-button" onClick={() => setIsDeleteConfirmed(true)}>
                    Would you Like to Delete this Channel?
                </button>
            )}
            {isDeleteConfirmed && (
                <ConfirmDeleteButton onClick={handleDeleteConfirmation} />
            )}
            <div className="edit-channel__header">
                <p>Edit Channel</p>
                <CloseCreateChannel />
            </div>
            <ChannelNameInput channelName={channelName} setChannelName={setChannelName} />
            <UserList setSelectedUsers={setSelectedUsers} />
            <div className="edit-channel__button-wrapper" onClick={updateChannel}>
                <p>Save Changes</p>
            </div>
        </div>
    );
};

export default EditChannel;