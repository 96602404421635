import React, { useState } from "react";
import { useChatContext } from "stream-chat-react";
import { UserList } from "./";

import {
  OrderSupports,
  PneumaticTechs,
  PneumaticSystems,
  ElectricActuators,
  ModularSolutions,
  FloflexFittingAndTubings,
  LightCurtainReers,
  VacuumSchmalzs,
  LinearRails,
  AluminumProfiles,
  SalesVisitRequests,
  PneumaticProportionals
} from "./DefaultChannel";
import { CloseCreateChannel } from "../assets";

const CreateChannel = ({ createType, setIsCreating }) => {
  const { client, setActiveChannel } = useChatContext();
  const [selectedUsers, setSelectedUsers] = useState([client.userID || ""]);
  const [selectedChannel, setSelectedChannel] = useState([
    client.channel || "",
  ]);
  const [channelName, setChannelName] = useState("");

  const createChannel = async (e) => {
    e.preventDefault();

    try {
      const newChannel = await client.channel(createType, channelName, {
        name: channelName,
        members: selectedUsers,
        channel: selectedChannel,
      });

      await newChannel.watch();

      setChannelName("");
      setIsCreating(false);
      setSelectedUsers([client.userID]);
      setActiveChannel(newChannel);
      setSelectedChannel([client.channel]);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="create-channel__container">
      <div className="create-channel__header">
        <p>
          {createType === "team"
            ? "Connect with an Expert"
            : "Send a Direct Message"}
        </p>
        <CloseCreateChannel setIsCreating={setIsCreating} />
      </div>
      {createType === "team" ? (
        <ul>
          <button className="create-channel-expert__button" onClick={OrderSupports}>Order Support</button> <br /><br />
          <button className="create-channel-expert__button" onClick={PneumaticTechs}>Pneumatic Tech</button> <br /><br />
          <button className="create-channel-expert__button" onClick={PneumaticSystems}>Pneumatic System</button> <br /><br />
          <button className="create-channel-expert__button" onClick={ElectricActuators}>Electric Actuator</button> <br /><br />
          <button className="create-channel-expert__button" onClick={ModularSolutions}>Modular Solutions</button> <br /><br />
          <button className="create-channel-expert__button" onClick={FloflexFittingAndTubings}>Floflex Fittings & Tubing</button> <br /><br />
          <button className="create-channel-expert__button" onClick={LightCurtainReers}>Light Curtain - Reer</button> <br /><br />
          <button className="create-channel-expert__button" onClick={VacuumSchmalzs}>Vacuum Schmalz</button> <br /><br />
          <button className="create-channel-expert__button" onClick={LinearRails}>Linear Rail</button> <br /><br />
          <button className="create-channel-expert__button" onClick={AluminumProfiles}>Aluminum Profiles</button> <br /><br />
          <button className="create-channel-expert__button" onClick={SalesVisitRequests}>Sales Visit Request</button> <br /><br />
          <button className="create-channel-expert__button" onClick={PneumaticProportionals}>Pneumatic Proportional</button> <br /><br />
        </ul>
      ) : (
        <UserList setSelectedUsers={setSelectedUsers} />
      )}
      <div className="create-channel__button-wrapper" onClick={createChannel}>
        <p>
          {createType === "team" ? null : "Create Message Group"}
        </p>
      </div>
    </div>
  );
};

export default CreateChannel;
