import React from "react";

document.title = "Flotronics Automation Application"

const PWAComponent = () => {
  return (
    <div className="app__buttons-main">
      <head>Flotronics Automation Application</head>
      <body className="app__buttons-main">
        <div className="app__buttons-main">
          <h2 className="app__button-header">
            Flotronics Automation <br /> Application
          </h2>
          <ul>
            <li>
              <a href="https://www.industrialmessanger.com/">
                <button className="app__button">INDUSTRIAL MESSENGER</button>
              </a>
            </li>
            <li>
              <a href="https://thepneumaticstore.com/">
                <button className="app__button">STOREFRONT</button>
              </a>
            </li>
            <li>
              <a href="https://thepneumaticstore.com/Category/Index/18466?webdesc=THE%20KNOWLEDGE%20CENTER">
                <button className="app__button">KNOWLEDGE CENTER</button>
              </a>
            </li>
            <li>
              <a href="https://the-modular-solution.com/">
                <button className="app__button">MODULAR SOLUTIONS</button>
              </a>
            </li>
          </ul>
        </div>
      </body>
    </div>
  );
};

export default PWAComponent;
