import React, { useState } from "react";
import { StreamChat } from "stream-chat";
import { Chat } from "stream-chat-react";
import Cookies from "universal-cookie";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { ChannelListContainer, ChannelContainer, Auth } from "./components";

import "stream-chat-react/dist/css/index.css";
import "./App.css";
import PWAComponent from "./components/PWAComponent";

const cookies = new Cookies();

const apiKey = "jd8dbxatk7vd";
const authToken = cookies.get("token");

const client = StreamChat.getInstance(apiKey);

if (authToken) {
  client.connectUser(
    {
      id: cookies.get("userId"),
      name: cookies.get("username"),
      fullName: cookies.get("fullName"),
      image: cookies.get("avatarURL"),
      hashedPassword: cookies.get("hashedPassword"),
      phoneNumber: cookies.get("phoneNumber"),
    },
    authToken
  );
}

const App = () => {
  const [createType, setCreateType] = useState("");
  const [isCreating, setIsCreating] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  if (!authToken) return <Auth />;

  return (
    <Router>
      <div className="app__wrapper">
        <Chat client={client} theme="team dark">
          <Routes>
          <Route path="/PWAComponent" element={<PWAComponent />} />
            <Route
              path="/"
              element={
                <React.Fragment>
                  <ChannelListContainer
                    isCreating={isCreating}
                    setIsCreating={setIsCreating}
                    setCreateType={setCreateType}
                    setIsEditing={setIsEditing}
                  />
                  <ChannelContainer
                    isCreating={isCreating}
                    setIsCreating={setIsCreating}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    createType={createType}
                  />
                </React.Fragment>
              }
            ></Route>
          </Routes>
        </Chat>
      </div>
    </Router>
  );
};

export default App;
